import {
  COLORS,
  LBTDatePicker,
  LBTTextField,
  Section,
} from '@laborability/components';
import { Dayjs } from 'dayjs';
import { useState } from 'react';

const Test = () => {
  const [date, setDate] = useState<string>('');

  return (
    <Section
      backgroundColor={COLORS.getInstance().WHITE}
      style={{ display: 'flex', flex: 1 }}
    >
      <LBTTextField label="Testo" onChange={() => {}}></LBTTextField>
      <LBTDatePicker label="Data di nascita" handleChange={() => {}} />
    </Section>
  );
};

export default Test;
