import { createHashRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import { currentCompanyConfigState } from '@laborability/commons';
import { useRecoilValue } from 'recoil';
import {
  CategoryPage,
  CookiePolicy,
  Fifth,
  Fifth01,
  Fifth02,
  Fifth03,
  Fifth04,
  Fifth05,
  Fifth06,
  Home,
  MeasureDetailPage,
  NoPage404,
  PrivacyPolicy,
  SearchMisure,
  SeverancePay,
  TagPage,
  QuestionaryLoader,
  QuestionaryCompletedLoader,
  UserMenu,
  IssueReport,
  RatingPage,
  MeasureReport,
  QuestionaryIndex,
  QuestionaryMenu,
  UserAccount,
  MyBonus,
  SpecialCases,
  Logout,
  Test,
} from './pages';
import Auth from './components/Auth';

export const APP_ROUTES = {
  HOME: 'home',
  MENU: 'menu',
  USER_ACCOUNT: 'user-account',
  QUESTIONARY_MENU: 'questionary-menu',
  FIFTH: 'fifth',
  FIFTH_01: 'fifth_01',
  FIFTH_02: 'fifth_02',
  FIFTH_03: 'fifth_03',
  FIFTH_04: 'fifth_04',
  FIFTH_05: 'fifth_05',
  FIFTH_06: 'fifth_06',
  SEVERANCE_PAY: 'severance-pay',
  CATEGORY: 'category',
  MEASURE_DETAIL: 'measure-detail',
  TAG: 'tag',
  SEARCH_MISURE: 'search-misure',
  COOKIE_POLICY: 'cookie-policy',
  PRIVACY_POLICY: 'privacy-policy',
  QUESTIONARY: 'questionary',
  RATE_SERVICE: 'rate-service',
  REPORT_ISSUE: 'report-issue',
  REPORT_MEASURE: 'report-measure',
  MY_CONCESSIONS: 'my-concessions',
  MY_BONUS: 'my-bonus',
  SPECIAL_CASES: 'special-cases',
  QUESTIONARY_LOADER: 'questionary-loader',
  QUESTIONARY_COMPLETED_LOADER: 'questionary-completed-loader',
  LOGOUT: 'logout',
};

export default function AppRoutes() {
  const { fifth_assignment, tfr, realm } = useRecoilValue(
    currentCompanyConfigState,
  );
  const hasQuestionaryAccess =
    !!process.env.REACT_APP_FE_QUESTIONARY_ACCESS?.split(', ').find(
      azienda => azienda === realm,
    );
  const fifthAssignmentRoutes = [
    {
      path: APP_ROUTES.FIFTH,
      element: <Fifth />,
      handle: {
        page_title: 'Cessione del quinto',
      },
    },
    {
      path: APP_ROUTES.FIFTH_01,
      element: <Fifth01 />,
      handle: {
        page_title: `Cos'è la cessione del quinto dello stipendio?`,
      },
    },
    {
      path: APP_ROUTES.FIFTH_02,
      element: <Fifth02 />,
      handle: {
        page_title: `Cos'è la cessione del quinto dello stipendio?`,
      },
    },
    {
      path: APP_ROUTES.FIFTH_03,
      element: <Fifth03 />,
      handle: {
        page_title: `Cos'è la cessione del quinto dello stipendio?`,
      },
    },
    {
      path: APP_ROUTES.FIFTH_04,
      element: <Fifth04 />,
      handle: {
        page_title: `Cos'è la cessione del quinto dello stipendio?`,
      },
    },
    {
      path: APP_ROUTES.FIFTH_05,
      element: <Fifth05 />,
      handle: {
        page_title: `Cos'è la cessione del quinto dello stipendio?`,
      },
    },
    {
      path: APP_ROUTES.FIFTH_06,
      element: <Fifth06 />,
    },
  ];
  const severancePayRoutes = [
    {
      path: APP_ROUTES.SEVERANCE_PAY,
      element: <SeverancePay />,
    },
  ];
  const questionaryRoutes = [
    {
      path: APP_ROUTES.QUESTIONARY,
      element: <QuestionaryIndex />,
    },
    {
      path: APP_ROUTES.QUESTIONARY_LOADER,
      element: <QuestionaryLoader />,
    },
    {
      path: APP_ROUTES.QUESTIONARY_COMPLETED_LOADER,
      element: <QuestionaryCompletedLoader />,
    },
    {
      path: APP_ROUTES.QUESTIONARY_MENU,
      element: <QuestionaryMenu />,
    },
  ];
  const router = createHashRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          element: <Auth />,
          children: [
            {
              path: `test`,
              element: <Test />,
            },
            {
              index: true,
              element: <NoPage404 />,
            },
            {
              path: APP_ROUTES.HOME,
              element: <Home />,
              handle: {
                page_title: 'Home page',
              },
            },
            {
              path: `${APP_ROUTES.CATEGORY}/:id`,
              element: <CategoryPage />,
            },
            {
              path: `${APP_ROUTES.MEASURE_DETAIL}/:id`,
              element: <MeasureDetailPage />,
            },
            {
              path: `${APP_ROUTES.TAG}/:id`,
              element: <TagPage />,
            },
            {
              path: APP_ROUTES.SEARCH_MISURE,
              element: <SearchMisure />,
            },

            {
              path: APP_ROUTES.MENU,
              element: <UserMenu />,
            },
            {
              path: APP_ROUTES.USER_ACCOUNT,
              element: <UserAccount />,
            },

            {
              path: APP_ROUTES.RATE_SERVICE,
              element: <RatingPage />,
            },
            {
              path: APP_ROUTES.REPORT_ISSUE,
              element: <IssueReport />,
            },
            {
              path: APP_ROUTES.REPORT_MEASURE,
              element: <MeasureReport />,
            },
            {
              path: APP_ROUTES.MY_BONUS,
              element: <MyBonus />,
            },
            {
              path: APP_ROUTES.SPECIAL_CASES,
              element: <SpecialCases />,
            },
            {
              path: APP_ROUTES.LOGOUT,
              element: <Logout />,
            },
            ...(tfr ? severancePayRoutes : []),
            ...(fifth_assignment ? fifthAssignmentRoutes : []),
            ...(hasQuestionaryAccess ? questionaryRoutes : []),
            {
              path: '*',
              element: <NoPage404 />,
            },
          ],
        },
        {
          path: APP_ROUTES.PRIVACY_POLICY,
          element: <PrivacyPolicy />,
        },
        {
          path: APP_ROUTES.COOKIE_POLICY,
          element: <CookiePolicy />,
        },
        {
          path: '*',
          element: <NoPage404 />,
        },
      ],
    },
    {
      path: '/*',
      element: <NoPage404 />,
    },
  ]);

  return <RouterProvider router={router} />;
}
