import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import '../assets/font.css';
import '../assets/fonts/source-sans-pro/source_sans_pro/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_black/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_black_italic/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_bold/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_bold_italic/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_extralight/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_extralight_italic/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_italic/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_light/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_light_italic/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_semibold/stylesheet.css';
import '../assets/fonts/source-sans-pro/source_sans_pro_semibold_italic/stylesheet.css';
import { COLORS } from './Colors';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    tangerine?: PaletteOptions['primary'];
    mintTonic?: PaletteOptions['primary'];
    lemonCake?: PaletteOptions['primary'];
    berryPopsicle?: PaletteOptions['primary'];
    lavander?: PaletteOptions['primary'];
    secondario?: PaletteOptions['primary'];
    'primary-negative'?: PaletteOptions['primary'];
    negative?: PaletteOptions['primary'];
  }
  interface PaletteColor {
    superdark?: string;
    hover?: string;
    superlight?: string;
    iperlight?: string;
  }

  interface SimplePaletteColorOptions {
    superlight?: string;
    iperlight?: string;
    darker?: string;
    hover?: string;
    selected?: string;
    focus?: string;
    focusVisible?: string;
    outlinedBorder?: string;
    neutral?: string;
    'primary-negative'?: string;
    negative?: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    invisible: true;
  }
  interface ButtonPropsSizeOverrides {
    invisible: true;
  }
  interface ButtonPropsColorOverrides {
    'primary-negative': true;
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    primary: true;
    primaryNegative: true;
    tangerine: true;
    mintTonic: true;
    berryPopsicle: true;
    lavander: true;
    neutral: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    delaDisplay1: true;
    delaDisplay2: true;
    delaHeadline: true;
    spGroteskTitle: true;
    spGroteskSubtitle1: true;
    spGroteskSubtitle2: true;
    spGroteskSubtitle3: true;
    sourceSubtitle1: true;
    sourceSubtitle2: true;
    sourceSubtitle3: true;
    bodyBlockquote: true;
    bodyText1: true;
    bodyText2: true;
    bodyLink: true;
    sourceCaption: true;
    smallCaps: true;
    smallCapsBold: true;
    //componenti
    alertTitle: true;
    alertDescription: true;
    buttonLarge: true;
    buttonMedium: true;
    buttonSmall: true;
    chipLabel: true;
    datePickerSelectedDate: true;
    datePickerHighlighted: true;
    datePickerSelectDate: true;
    datePickerNumbers: true;
    inputFormLabel: true;
    inputHelper: true;
    inputApiceForm: true;
    listTitle: true;
    listDescription: true;
    miscTitleSpGrotesk: true;
    miscTitleSource: true;
    miscDescription: true;
    progressSmallLabel: true;
    datepickerTitle: true;
    datepickerSubtitle: true;
  }
}

declare module '@mui/material/MenuItem' {
  interface MenuItemOwnProps {
    value?: string | readonly string[] | number | undefined | null;
  }
}

declare module '@mui/material/Alert' {
  interface AlertPropsVariantOverrides {
    contained: true;
    negative: true;
    filled: false;
  }
  interface AlertPropsColorOverrides {
    error: true;
    success: true;
    info: true;
    warning: true;
    neutral: true;
    all_incomes: true;
    online_or_phone: true;
    entertainment: true;
    help: true;
  }
  interface AlertPropsSeverityOverrides {
    primary: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    negative: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    mintTonic: true;
    grey: true;
    tangerine: true;
    lemonCake: true;
    berry: true;
    lavander: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    smartphone: true;
    tablet: true;
    desktop: true;
  }
}

export enum FONTS {
  SOURCE_SANS_PRO = '"Source Sans Pro"',
  SOURCE_SANS_PRO_BOLD = '"Source Sans Pro Bold"',
  SOURCE_SANS_PRO_BLACK = '"Source Sans Pro Black"',
  SOURCE_SANS_PRO_LIGHT = '"Source Sans Pro Light"',
  SPACE_GROTESK = '"Space Grotesk"',
  DELA_GOTHIC_ONE = '"Dela Gothic One"',
}

export const AppTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      smartphone: 599,
      tablet: 719,
      desktop: 839,
    },
  },
  components: {
    MuiRadio: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.color === 'primary' && {
            '&.MuiRadio-root': {
              color: theme.palette.primary.dark,
              '&:hover': {
                backgroundColor: `${theme.palette.primary.main}26`,
              },
            },
            '&.Mui-checked': {
              '&.MuiRadio-root': {
                color: theme.palette.primary.dark,
              },
            },
          }),
          ...(ownerState.color === 'primaryNegative' && {
            '&.MuiRadio-root': {
              color: COLORS.getInstance().BW_GREYS_MEDIUM_DARK,
              '&:hover': {
                backgroundColor: COLORS.getInstance().BW_GREYS_MAIN,
              },
            },
            '&.Mui-checked': {
              '&.MuiRadio-root': {
                color: COLORS.getInstance().BW_GREYS_DARK,
              },
            },
          }),
          ...(ownerState.color === 'tangerine' && {
            '&.MuiRadio-root': {
              color: COLORS.getInstance().TANGERINE_DARK,
              '&:hover': {
                backgroundColor: `${COLORS.getInstance().TANGERINE_MAIN}26`,
              },
            },
            '&.Mui-checked': {
              '&.MuiRadio-root': {
                color: COLORS.getInstance().TANGERINE_SUPERDARK,
              },
            },
          }),
          ...(ownerState.color === 'mintTonic' && {
            '&.MuiRadio-root': {
              color: COLORS.getInstance().MINT_TONIC_DARK,
              '&:hover': {
                backgroundColor: `${COLORS.getInstance().MINT_TONIC_MAIN}26`,
              },
            },
            '&.Mui-checked': {
              '&.MuiRadio-root': {
                color: COLORS.getInstance().MINT_TONIC_SUPERDARK,
              },
            },
          }),
          ...(ownerState.color === 'berryPopsicle' && {
            '&.MuiRadio-root': {
              color: COLORS.getInstance().BERRY_POPSICLE_DARK,
              '&:hover': {
                backgroundColor: `${COLORS.getInstance().BERRY_POPSICLE_MAIN}26`,
              },
            },
            '&.Mui-checked': {
              '&.MuiRadio-root': {
                color: COLORS.getInstance().BERRY_POPSICLE_SUPERDARK,
              },
            },
          }),
          ...(ownerState.color === 'lavander' && {
            '&.MuiRadio-root': {
              color: COLORS.getInstance().LAVANDER_DARK,
              '&:hover': {
                backgroundColor: `${COLORS.getInstance().LAVANDER_MAIN}26`,
              },
            },
            '&.Mui-checked': {
              '&.MuiRadio-root': {
                color: COLORS.getInstance().LAVANDER_DARK,
              },
            },
          }),
        }),
      },
    },
    MuiButton: {
      defaultProps: { disableRipple: true },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          borderRadius: '10px',
          padding: '8px 16px',
          boxShadow: 'none !important',
          textTransform: 'none',
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'contained' && {
              '.MuiTypography-root': {
                color: COLORS.getInstance().BW_GREYS_JET_BLACK,
              },
              backgroundColor: theme.palette.primary.main,
              border: `1px solid transparent`,
              '&:hover': {
                '.MuiTypography-root': {
                  color: COLORS.getInstance().BW_GREYS_JET_BLACK,
                },
                backgroundColor: theme.palette.primary.light,
                border: `1px solid transparent`,
              },
              '&:active': {
                backgroundColor: theme.palette.primary.superlight,
                border: `1px solid ${theme.palette.primary.dark}`,
              },
              '&:disabled': {
                '.MuiTypography-root': {
                  color: COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT,
                },
                backgroundColor: COLORS.getInstance().BW_GREYS_SUPERLIGHT,
              },
            }),
          ...(ownerState.color === 'primary-negative' &&
            ownerState.variant === 'contained' && {
              '.MuiTypography-root': { color: COLORS.getInstance().WHITE },
              backgroundColor: COLORS.getInstance().BW_GREYS_JET_BLACK,
              border: `1px solid transparent`,
              '&:hover': {
                '.MuiTypography-root': { color: COLORS.getInstance().WHITE },
                backgroundColor: COLORS.getInstance().BW_GREYS_DARK,
                border: `1px solid transparent`,
              },
              '&:active': {
                backgroundColor: COLORS.getInstance().BW_GREYS_MEDIUM,
                border: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              },
              '&:disabled': {
                '.MuiTypography-root': { color: theme.palette.primary.main },
                backgroundColor: theme.palette.primary.light,
              },
            }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'outlined' && {
              '.MuiTypography-root': { color: theme.palette.primary.dark },
              backgroundColor: 'transparent',
              border: `1px solid ${theme.palette.primary.dark}`,
              '&:hover': {
                '.MuiTypography-root': { color: theme.palette.primary.dark },
                backgroundColor: theme.palette.primary.iperlight,
                border: `1px solid ${theme.palette.primary.dark}`,
              },
              '&:active': {
                backgroundColor: theme.palette.primary.superlight,
                border: `2px solid ${theme.palette.primary.dark}`,
              },
              '&:disabled': {
                '.MuiTypography-root': {
                  color: COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT,
                },
                backgroundColor: 'transparent',
                border: `1px solid ${COLORS.getInstance().BW_GREYS_SUPERLIGHT}`,
              },
            }),
          ...(ownerState.color === 'primary-negative' &&
            ownerState.variant === 'outlined' && {
              '.MuiTypography-root': {
                color: COLORS.getInstance().BW_GREYS_JET_BLACK,
              },
              backgroundColor: 'transparent',
              border: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              '&:hover': {
                '.MuiTypography-root': {
                  color: COLORS.getInstance().BW_GREYS_JET_BLACK,
                },
                backgroundColor: `${COLORS.getInstance().BLACK}1A`,
                border: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              },
              '&:active': {
                border: `2px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
                backgroundColor: `${COLORS.getInstance().BLACK}33`,
              },
              '&:disabled': {
                '.MuiTypography-root': { color: theme.palette.primary.main },
                backgroundColor: 'transparent',
                border: `1px solid ${theme.palette.primary.main}`,
              },
            }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'invisible' && {
              '.MuiTypography-root': { color: theme.palette.primary.dark },
              backgroundColor: 'transparent',
              border: `1px solid transparent`,
              '&:hover': {
                '.MuiTypography-root': { color: theme.palette.primary.dark },
                backgroundColor: theme.palette.primary.iperlight,
                border: `1px solid transparent`,
              },
              '&:active': {
                border: `1px solid ${theme.palette.primary.dark}`,
                backgroundColor: theme.palette.primary.superlight,
              },
              '&:disabled': {
                '.MuiTypography-root': {
                  color: COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT,
                },
              },
            }),
          ...(ownerState.color === 'primary-negative' &&
            ownerState.variant === 'invisible' && {
              '.MuiTypography-root': {
                color: COLORS.getInstance().BW_GREYS_JET_BLACK,
              },
              backgroundColor: 'transparent',
              border: `1px solid transparent`,
              '&:hover': {
                '.MuiTypography-root': {
                  color: COLORS.getInstance().BW_GREYS_JET_BLACK,
                },
                backgroundColor: `${COLORS.getInstance().BLACK}1A`,
                border: `1px solid transparent`,
              },
              '&:active': {
                border: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
                backgroundColor: `${COLORS.getInstance().BLACK}33`,
              },
              '&:disabled': {
                '.MuiTypography-root': { color: theme.palette.primary.main },
              },
            }),
          ...(ownerState.color === 'error' &&
            ownerState.variant === 'invisible' && {
              '.MuiTypography-root': {
                color: COLORS.getInstance().ERROR_SUPERDARK,
              },
              backgroundColor: 'transparent',
              border: `1px solid transparent`,
              '&:hover': {
                '.MuiTypography-root': {
                  color: COLORS.getInstance().ERROR_SUPERDARK,
                },
                backgroundColor: `${COLORS.getInstance().ERROR_SUPERLIGHT}`,
                border: `1px solid transparent`,
              },
              '&:active': {
                border: `1px solid ${COLORS.getInstance().ERROR_SUPERDARK}`,
                backgroundColor: `${COLORS.getInstance().ERROR_SUPERLIGHT}`,
              },
              '&:disabled': {
                '.MuiTypography-root': { color: theme.palette.primary.main },
              },
            }),
        }),
        sizeLarge: {
          height: '56px',
        },
        sizeMedium: {
          height: '47px',
        },
        sizeSmall: {
          height: '36px',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === 'neutral' && {
            color: COLORS.getInstance().BW_GREYS_JET_BLACK,
          }),
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '& .MuiAlert-icon': {
            paddingTop: '7px',
          },
          '& .MuiAlert-message': {
            padding: '5px 0px 8px 0px',
          },
          ...(ownerState.color === 'all_incomes' &&
            ownerState.variant === 'standard' && {
              backgroundColor: `${COLORS.getInstance().PRIMARY_IPERLIGHT}`,
              color: `${COLORS.getInstance().PRIMARY_SUPERDARK}`,
            }),
          ...(ownerState.color === 'online_or_phone' &&
            ownerState.variant === 'standard' && {
              backgroundColor: `${COLORS.getInstance().PRIMARY_IPERLIGHT}`,
              color: `${COLORS.getInstance().PRIMARY_SUPERDARK}`,
            }),
        }),
      },
      variants: [
        {
          props: { variant: 'contained', severity: 'error' },
          style: {
            backgroundColor: COLORS.getInstance().TANGERINE_MAIN,
            color: COLORS.getInstance().TANGERINE_CONTRAST,
            fill: COLORS.getInstance().TANGERINE_CONTRAST,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'contained' && color === 'success',
          style: {
            backgroundColor: COLORS.getInstance().MINT_TONIC_MAIN,
            color: COLORS.getInstance().SUCCESS_CONTRAST,
            fill: COLORS.getInstance().SUCCESS_CONTRAST,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'contained' && color === 'info',
          style: {
            backgroundColor: COLORS.getInstance().PRIMARY_MAIN,
            color: COLORS.getInstance().PRIMARY_CONTRAST,
            fill: COLORS.getInstance().PRIMARY_CONTRAST,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'contained' && color === 'warning',
          style: {
            backgroundColor: COLORS.getInstance().LEMON_CAKE_MAIN,
            color: COLORS.getInstance().LEMON_CAKE_CONTRAST,
            fill: COLORS.getInstance().LEMON_CAKE_CONTRAST,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'contained' && color === 'neutral',
          style: {
            backgroundColor: COLORS.getInstance().BW_GREYS_MEDIUM_DARK,
            color: COLORS.getInstance().WHITE,
            fill: COLORS.getInstance().WHITE,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'contained' && color === 'entertainment',
          style: {
            backgroundColor: COLORS.getInstance().BERRY_POPSICLE_MAIN,
            color: COLORS.getInstance().BERRY_POPSICLE_CONTRAST,
            fill: COLORS.getInstance().BERRY_POPSICLE_CONTRAST,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'contained' && color === 'help',
          style: {
            backgroundColor: COLORS.getInstance().LAVANDER_MAIN,
            color: COLORS.getInstance().LAVANDER_CONTRAST,
            fill: COLORS.getInstance().LAVANDER_CONTRAST,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'outlined' && color === 'error',
          style: {
            backgroundColor: 'transparent',
            color: COLORS.getInstance().TANGERINE_SUPERDARK,
            fill: COLORS.getInstance().TANGERINE_SUPERDARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'outlined' && color === 'success',
          style: {
            backgroundColor: 'transparent',
            color: COLORS.getInstance().MINT_TONIC_SUPERDARK,
            fill: COLORS.getInstance().MINT_TONIC_SUPERDARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'outlined' && color === 'info',
          style: {
            backgroundColor: 'transparent',
            color: COLORS.getInstance().PRIMARY_SUPERDARK,
            fill: COLORS.getInstance().PRIMARY_SUPERDARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'outlined' && color === 'warning',
          style: {
            backgroundColor: 'transparent',
            color: COLORS.getInstance().LEMON_CAKE_DARK,
            fill: COLORS.getInstance().LEMON_CAKE_DARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'outlined' && color === 'neutral',
          style: {
            backgroundColor: 'transparent',
            color: COLORS.getInstance().BW_GREYS_DARK,
            fill: COLORS.getInstance().BW_GREYS_DARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'outlined' && color === 'entertainment',
          style: {
            backgroundColor: 'transparent',
            color: COLORS.getInstance().BERRY_POPSICLE_SUPERDARK,
            fill: COLORS.getInstance().BERRY_POPSICLE_SUPERDARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'outlined' && color === 'help',
          style: {
            backgroundColor: 'transparent',
            color: COLORS.getInstance().LAVANDER_DARK,
            fill: COLORS.getInstance().LAVANDER_DARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'standard' && color === 'error',
          style: {
            backgroundColor: COLORS.getInstance().TANGERINE_IPERLIGHT,
            color: COLORS.getInstance().TANGERINE_SUPERDARK,
            fill: COLORS.getInstance().TANGERINE_SUPERDARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'standard' && color === 'success',
          style: {
            backgroundColor: COLORS.getInstance().MINT_TONIC_IPERLIGHT,
            color: COLORS.getInstance().MINT_TONIC_SUPERDARK,
            fill: COLORS.getInstance().MINT_TONIC_SUPERDARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'standard' && color === 'info',
          style: {
            backgroundColor: COLORS.getInstance().PRIMARY_IPERLIGHT,
            color: COLORS.getInstance().PRIMARY_SUPERDARK,
            fill: COLORS.getInstance().PRIMARY_SUPERDARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'standard' && color === 'warning',
          style: {
            backgroundColor: COLORS.getInstance().LEMON_CAKE_IPERLIGHT,
            color: COLORS.getInstance().LEMON_CAKE_CONTRAST,
            fill: COLORS.getInstance().LEMON_CAKE_CONTRAST,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'standard' && color === 'neutral',
          style: {
            backgroundColor: COLORS.getInstance().BW_GREYS_MAIN,
            color: COLORS.getInstance().BW_GREYS_DARK,
            fill: COLORS.getInstance().BW_GREYS_DARK,
          },
        },

        {
          props: ({ variant, color }) =>
            variant === 'standard' && color === 'entertainment',
          style: {
            backgroundColor: COLORS.getInstance().BERRY_POPSICLE_IPERLIGHT,
            color: COLORS.getInstance().BERRY_POPSICLE_SUPERDARK,
            fill: COLORS.getInstance().BERRY_POPSICLE_SUPERDARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'standard' && color === 'help',
          style: {
            backgroundColor: COLORS.getInstance().LAVANDER_IPERLIGHT,
            color: COLORS.getInstance().LAVANDER_DARK,
            fill: COLORS.getInstance().LAVANDER_DARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'negative' && color === 'error',
          style: {
            backgroundColor: COLORS.getInstance().TANGERINE_CONTRAST,
            color: COLORS.getInstance().TANGERINE_MAIN,
            fill: COLORS.getInstance().TANGERINE_MAIN,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'negative' && color === 'success',
          style: {
            backgroundColor: COLORS.getInstance().MINT_TONIC_CONTRAST,
            color: COLORS.getInstance().MINT_TONIC_MAIN,
            fill: COLORS.getInstance().MINT_TONIC_MAIN,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'negative' && color === 'info',
          style: {
            backgroundColor: COLORS.getInstance().PRIMARY_CONTRAST,
            color: COLORS.getInstance().PRIMARY_MAIN,
            fill: COLORS.getInstance().PRIMARY_MAIN,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'negative' && color === 'warning',
          style: {
            backgroundColor: COLORS.getInstance().LEMON_CAKE_CONTRAST,
            color: COLORS.getInstance().LEMON_CAKE_MAIN,
            fill: COLORS.getInstance().LEMON_CAKE_MAIN,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'negative' && color === 'neutral',
          style: {
            backgroundColor: COLORS.getInstance().WHITE,
            color: COLORS.getInstance().BW_GREYS_MEDIUM_DARK,
            fill: COLORS.getInstance().BW_GREYS_MEDIUM_DARK,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'negative' && color === 'entertainment',
          style: {
            backgroundColor: COLORS.getInstance().BERRY_POPSICLE_CONTRAST,
            color: COLORS.getInstance().BERRY_POPSICLE_MAIN,
            fill: COLORS.getInstance().BERRY_POPSICLE_MAIN,
          },
        },
        {
          props: ({ variant, color }) =>
            variant === 'negative' && color === 'help',
          style: {
            backgroundColor: COLORS.getInstance().LAVANDER_CONTRAST,
            color: COLORS.getInstance().LAVANDER_MAIN,
            fill: COLORS.getInstance().LAVANDER_MAIN,
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'filled' && {
              backgroundColor: `${COLORS.getInstance().PRIMARY_SUPERDARK}`,
              fill: `${COLORS.getInstance().PRIMARY_SUPERDARK} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().WHITE}`,
              },
            }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().PRIMARY_SUPERDARK}`,
              fill: `${COLORS.getInstance().PRIMARY_SUPERDARK} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().PRIMARY_SUPERDARK}`,
              },
            }),
          ...(ownerState.color === 'mintTonic' &&
            ownerState.variant === 'filled' && {
              backgroundColor: `${COLORS.getInstance().MINT_TONIC_MAIN}`,
              fill: `${COLORS.getInstance().BW_GREYS_JET_BLACK} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              },
            }),
          ...(ownerState.color === 'mintTonic' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().MINT_TONIC_MAIN}`,
              fill: `${COLORS.getInstance().MINT_TONIC_MAIN} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().MINT_TONIC_MAIN}`,
              },
            }),
          ...(ownerState.color === 'grey' &&
            ownerState.variant === 'filled' && {
              backgroundColor: `${COLORS.getInstance().BW_GREYS_SUPERLIGHT}`,
              fill: `${COLORS.getInstance().BW_GREYS_JET_BLACK} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              },
            }),
          ...(ownerState.color === 'grey' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().BW_GREYS_SUPERLIGHT}`,
              fill: `${COLORS.getInstance().BW_GREYS_JET_BLACK} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              },
            }),
          ...(ownerState.color === 'warning' &&
            ownerState.variant === 'filled' && {
              backgroundColor: `${COLORS.getInstance().WARNING_MAIN}`,
              fill: `${COLORS.getInstance().BW_GREYS_JET_BLACK} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              },
            }),
          ...(ownerState.color === 'warning' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().WARNING_MAIN}`,
              fill: `${COLORS.getInstance().WARNING_MAIN} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().WARNING_MAIN}`,
              },
            }),
          ...(ownerState.color === 'error' &&
            ownerState.variant === 'filled' && {
              backgroundColor: `${COLORS.getInstance().ERROR_MAIN}`,
              fill: `${COLORS.getInstance().WHITE} !important`,
              '.MuiTypography-root': { color: `${COLORS.getInstance().WHITE}` },
            }),
          ...(ownerState.color === 'error' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().ERROR_MAIN}`,
              fill: `${COLORS.getInstance().ERROR_MAIN} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().ERROR_MAIN}`,
              },
            }),
          ...(ownerState.color === 'tangerine' &&
            ownerState.variant === 'filled' && {
              backgroundColor: `${COLORS.getInstance().TANGERINE_SUPERDARK}`,
              fill: `${COLORS.getInstance().WHITE} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().WHITE}`,
              },
            }),
          ...(ownerState.color === 'tangerine' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().TANGERINE_SUPERDARK}`,
              fill: `${COLORS.getInstance().TANGERINE_SUPERDARK} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().TANGERINE_SUPERDARK}`,
              },
            }),
          ...(ownerState.color === 'success' &&
            ownerState.variant === 'filled' && {
              backgroundColor: `${COLORS.getInstance().SUCCESS_MAIN}`,
              fill: `${COLORS.getInstance().WHITE} !important`,
              '.MuiTypography-root': { color: `${COLORS.getInstance().WHITE}` },
            }),
          ...(ownerState.color === 'success' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().SUCCESS_MAIN}`,
              fill: `${COLORS.getInstance().SUCCESS_MAIN} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().SUCCESS_MAIN}`,
              },
            }),
          ...(ownerState.color === 'lemonCake' &&
            ownerState.variant === 'filled' && {
              backgroundColor: `${COLORS.getInstance().LEMON_CAKE_MAIN}`,
              fill: `${COLORS.getInstance().BW_GREYS_JET_BLACK} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              },
            }),
          ...(ownerState.color === 'lemonCake' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().LEMON_CAKE_MAIN}`,
              fill: `${COLORS.getInstance().LEMON_CAKE_MAIN} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().LEMON_CAKE_MAIN}`,
              },
            }),
          ...(ownerState.color === 'berry' &&
            ownerState.variant === 'filled' && {
              backgroundColor: `${COLORS.getInstance().BERRY_POPSICLE_MAIN}`,
              fill: `${COLORS.getInstance().BW_GREYS_JET_BLACK} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              },
            }),
          ...(ownerState.color === 'berry' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().BERRY_POPSICLE_MAIN}`,
              fill: `${COLORS.getInstance().BERRY_POPSICLE_MAIN} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().BERRY_POPSICLE_MAIN}`,
              },
            }),
          ...(ownerState.color === 'lavander' &&
            ownerState.variant === 'filled' && {
              backgroundColor: `${COLORS.getInstance().LAVANDER_MAIN}`,
              fill: `${COLORS.getInstance().BW_GREYS_JET_BLACK} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().BW_GREYS_JET_BLACK}`,
              },
            }),
          ...(ownerState.color === 'lavander' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().LAVANDER_MAIN}`,
              fill: `${COLORS.getInstance().LAVANDER_MAIN} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().LAVANDER_MAIN}`,
              },
            }),
          ...(ownerState.color === 'info' &&
            ownerState.variant === 'filled' && {
              backgroundColor: `${COLORS.getInstance().INFO_MAIN}`,
              fill: `${COLORS.getInstance().WHITE} !important`,
              '.MuiTypography-root': { color: `${COLORS.getInstance().WHITE}` },
            }),
          ...(ownerState.color === 'info' &&
            ownerState.variant === 'outlined' && {
              borderColor: `${COLORS.getInstance().INFO_MAIN}`,
              fill: `${COLORS.getInstance().INFO_MAIN} !important`,
              '.MuiTypography-root': {
                color: `${COLORS.getInstance().INFO_MAIN}`,
              },
            }),
        }),
      },
    },
    MuiSwitch: {
      variants: [
        {
          props: { color: 'negative' },
          style: {
            '&.MuiSwitch-root': {
              '& .Mui-checked': {
                '& .MuiSwitch-thumb': {
                  color: `${COLORS.getInstance().NEGATIVE}`,
                },
                '+ .MuiSwitch-track': {
                  backgroundColor: `${COLORS.getInstance().BW_GREYS_DARK}`,
                },
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.disabled === false && {
            '&:hover': {
              '& .MuiSwitch-thumb': {
                outline: `1px solid ${COLORS.getInstance().BLACK}`,
              },
              '& .Mui-checked': {
                '& .MuiSwitch-thumb': {
                  outline: 'none',
                },
              },
            },
          }),
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: '54px',
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: '56px',
          '& .MuiInputLabel-root': {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            '&.Mui-focused': {
              color: COLORS.getInstance().BW_GREYS_MEDIUM,
            },
          },

          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            fontFamily: FONTS.SOURCE_SANS_PRO,
            backgroundColor: COLORS.getInstance().WHITE,

            '&.Mui-focused fieldset': {
              borderColor: COLORS.getInstance().PRIMARY_MAIN,
            },
          },
          '& .MuiFormHelperText-root': {
            fontFamily: FONTS.SOURCE_SANS_PRO,
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.size === 'small' && {
            minHeight: '52px',
          }),
          minWidth: '250px',
          borderRadius: '12px',
          '& .MuiInputLabel-root': {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            '&.Mui-focused': {
              color: COLORS.getInstance().BW_GREYS_MEDIUM,
            },
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: FONTS.SOURCE_SANS_PRO,
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: FONTS.SOURCE_SANS_PRO,
          marginLeft: '0px',
          marginTop: '8px',
          width: '416px',
        }),
      },
    },
    MuiModal: {
      styleOverrides: {
        root: () => ({
          background: `${COLORS.getInstance().BLACK}CC`,
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'delaDisplay1' && {
            fontFamily: FONTS.DELA_GOTHIC_ONE,
            fontSize: '44px',
            lineHeight: '50px',
            letterSpacing: '0.88px',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'delaDisplay2' && {
            fontFamily: FONTS.DELA_GOTHIC_ONE,
            fontSize: '30px',
            lineHeight: '36px',
            letterSpacing: '0px',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'delaHeadline' && {
            fontFamily: FONTS.DELA_GOTHIC_ONE,
            fontSize: '22px',
            lineHeight: '30px',
            letterSpacing: '0px',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'spGroteskTitle' && {
            fontFamily: FONTS.SPACE_GROTESK,
            fontSize: '45px',
            lineHeight: '40px',
            letterSpacing: '0px',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'spGroteskSubtitle1' && {
            fontFamily: FONTS.SPACE_GROTESK,
            fontSize: '25px',
            lineHeight: '35px',
            letterSpacing: '0px',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'spGroteskSubtitle2' && {
            fontFamily: FONTS.SPACE_GROTESK,
            fontSize: '20px',
            lineHeight: '30px',
            letterSpacing: '0px',
            fontWeight: 500,
          }),
          ...(ownerState.variant === 'spGroteskSubtitle3' && {
            fontFamily: FONTS.SPACE_GROTESK,
            fontSize: '17px',
            lineHeight: '24px',
            letterSpacing: '0px',
            fontWeight: 500,
          }),
          ...(ownerState.variant === 'sourceSubtitle1' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_BLACK,
            fontSize: '25px',
            lineHeight: '33px',
            letterSpacing: '0.5px',
            fontWeight: 900,
          }),
          ...(ownerState.variant === 'sourceSubtitle2' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
            fontSize: '20px',
            lineHeight: '26px',
            letterSpacing: '0.06px',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'sourceSubtitle3' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
            fontSize: '17px',
            lineHeight: '24px',
            letterSpacing: '0.051px',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'bodyBlockquote' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_LIGHT,
            fontSize: '20px',
            lineHeight: '26px',
            letterSpacing: '0.4px',
            fontWeight: 300,
          }),
          ...(ownerState.variant === 'bodyText1' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0px',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'bodyLink' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.32px',
            textDecoration: 'underline',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'bodyText2' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0px',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'sourceCaption' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0px',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'smallCapsBold' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
            fontSize: '14px',
            lineHeight: '16px',
            letterSpacing: '0.26px',
            fontVariant: 'all-small-caps',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'smallCaps' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '14px',
            lineHeight: '16px',
            letterSpacing: '0.26px',
            fontVariant: 'all-small-caps',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'alertTitle' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
            fontSize: '17px',
            lineHeight: '150%',
            letterSpacing: '2%',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'alertDescription' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '14px',
            lineHeight: '120%',
            letterSpacing: '2%',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'buttonLarge' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
            fontSize: '16px',
            lineHeight: '18px',
            letterSpacing: '2%',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'buttonMedium' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
            fontSize: '14px',
            lineHeight: '140%',
            letterSpacing: '2%',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'buttonSmall' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
            fontSize: '13px',
            lineHeight: '16px',
            letterSpacing: '2%',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'chipLabel' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '13px',
            lineHeight: '16px',
            letterSpacing: '2%',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'datePickerSelectedDate' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_LIGHT,
            fontSize: '40px',
            lineHeight: '40px',
            letterSpacing: '0px',
            fontWeight: 300,
          }),
          ...(ownerState.variant === 'datePickerHighlighted' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
            fontSize: '14px',
            lineHeight: 'auto',
            letterSpacing: '0px',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'datePickerSelectDate' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '12px',
            lineHeight: '250%',
            letterSpacing: '1%',
            textTransform: 'uppercase',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'datePickerNumbers' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '14px',
            lineHeight: 'auto',
            letterSpacing: '0px',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'inputFormLabel' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '16px',
            lineHeight: '18px',
            letterSpacing: '0px',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'inputHelper' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '12px',
            lineHeight: '14px',
            letterSpacing: '4%',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'inputApiceForm' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '11px',
            lineHeight: '11px',
            letterSpacing: '2%',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'listTitle' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '16px',
            lineHeight: '150%',
            letterSpacing: '2%',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'listDescription' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '14px',
            lineHeight: '120%',
            letterSpacing: '2%',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'miscTitleSpGrotesk' && {
            fontFamily: FONTS.SPACE_GROTESK,
            fontSize: '25px',
            lineHeight: '30px',
            letterSpacing: '0px',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'miscTitleSource' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
            fontSize: '20px',
            lineHeight: '26px',
            letterSpacing: '0px',
            fontWeight: 700,
          }),
          ...(ownerState.variant === 'miscDescription' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0px',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'progressSmallLabel' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '5%',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'datepickerTitle' && {
            fontFamily: FONTS.SOURCE_SANS_PRO,
            fontSize: '12px',
            lineHeight: '30px',
            letterSpacing: '1px',
            fontWeight: 400,
          }),
          ...(ownerState.variant === 'datepickerSubtitle' && {
            fontFamily: FONTS.SOURCE_SANS_PRO_LIGHT,
            fontSize: '40px',
            lineHeight: '40px',
            fontWeight: 300,
          }),
        }),
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontFamily: FONTS.SOURCE_SANS_PRO,
          fontSize: '14px',
          color: COLORS.getInstance().BW_GREYS_MEDIUM_DARK,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: FONTS.SOURCE_SANS_PRO,
          fontSize: '14px',
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: ({ theme }) => ({
          fontFamily: FONTS.SOURCE_SANS_PRO,
          fontSize: '14px',
          '&.Mui-selected': {
            fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
            backgroundColor: theme.palette.primary.main,
            color: COLORS.getInstance().WHITE,
          },
        }),
      },
    },
  },
  palette: {
    primary: {
      main: COLORS.getInstance().PRIMARY_MAIN,
      dark: COLORS.getInstance().PRIMARY_DARK,
      light: COLORS.getInstance().PRIMARY_LIGHT,
      superlight: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      iperlight: COLORS.getInstance().PRIMARY_IPERLIGHT,
      contrastText: COLORS.getInstance().PRIMARY_CONTRAST,
      hover: COLORS.getInstance().PRIMARY_IPERLIGHT,
      selected: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      focus: COLORS.getInstance().PRIMARY_LIGHT,
      focusVisible: '#7098F6',
      outlinedBorder: '#6986F0',
    },
    'primary-negative': {
      main: COLORS.getInstance().PRIMARY_MAIN,
      dark: COLORS.getInstance().PRIMARY_DARK,
      light: COLORS.getInstance().PRIMARY_LIGHT,
      superlight: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      iperlight: COLORS.getInstance().PRIMARY_IPERLIGHT,
      contrastText: COLORS.getInstance().PRIMARY_CONTRAST,
      hover: COLORS.getInstance().PRIMARY_IPERLIGHT,
      selected: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      focus: COLORS.getInstance().PRIMARY_LIGHT,
      focusVisible: '#7098F6',
      outlinedBorder: '#6986F0',
    },
    negative: {
      main: COLORS.getInstance().PRIMARY_MAIN,
      dark: COLORS.getInstance().PRIMARY_DARK,
      light: COLORS.getInstance().PRIMARY_LIGHT,
      superlight: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      iperlight: COLORS.getInstance().PRIMARY_IPERLIGHT,
      contrastText: COLORS.getInstance().PRIMARY_CONTRAST,
      hover: COLORS.getInstance().PRIMARY_IPERLIGHT,
      selected: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      focus: COLORS.getInstance().PRIMARY_LIGHT,
      focusVisible: '#7098F6',
      outlinedBorder: '#6986F0',
    },
    tangerine: {
      main: COLORS.getInstance().TANGERINE_MAIN,
      dark: COLORS.getInstance().TANGERINE_DARK,
      superlight: COLORS.getInstance().TANGERINE_SUPERLIGHT,
      contrastText: COLORS.getInstance().TANGERINE_CONTRAST,
    },
    mintTonic: {},
    lemonCake: {
      main: COLORS.getInstance().LEMON_CAKE_MAIN,
      dark: COLORS.getInstance().LEMON_CAKE_MAIN,
      light: COLORS.getInstance().LEMON_CAKE_MAIN,
      contrastText: COLORS.getInstance().LEMON_CAKE_MAIN,
    },
    berryPopsicle: {},
    lavander: {},
    secondario: {},
    error: {
      main: COLORS.getInstance().ERROR_MAIN,
      dark: COLORS.getInstance().ERROR_DARK,
      light: COLORS.getInstance().ERROR_LIGHT,
      contrastText: COLORS.getInstance().ERROR_CONTRAST,
    },
    warning: {
      main: COLORS.getInstance().WARNING_MAIN,
      dark: COLORS.getInstance().WARNING_DARK,
      light: COLORS.getInstance().WARNING_LIGHT,
      contrastText: COLORS.getInstance().WARNING_CONTRAST,
    },
    info: {
      main: COLORS.getInstance().INFO_MAIN,
      dark: COLORS.getInstance().INFO_DARK,
      light: COLORS.getInstance().INFO_DARK,
      contrastText: COLORS.getInstance().INFO_CONTRAST,
    },
    success: {
      main: COLORS.getInstance().SUCCESS_MAIN,
      dark: COLORS.getInstance().SUCCESS_DARK,
      light: COLORS.getInstance().SUCCESS_LIGHT,
      contrastText: COLORS.getInstance().SUCCESS_CONTRAST,
    },
    neutral: {
      main: COLORS.getInstance().NEUTRAL,
      dark: COLORS.getInstance().NEUTRAL,
      light: COLORS.getInstance().NEUTRAL,
      contrastText: COLORS.getInstance().WHITE,
    },
  },
});
